import React from "react";
import "../Css/Contact.css";
import AnimatedContactHero from "./UI/AnimatedContactHero";

const Contact = () => {
  const handleOnSubmit = (e) => {
    e.preventDefault();
    console.log("Hello from contact");
  };


  const viewMap1 = () => {
    window.open("https://maps.app.goo.gl/nUtvY9XeThRGNKH88", "_blank")
  }

  const viewMap2 = () => {
    window.open("https://maps.app.goo.gl/g9PMvQaAD1gA8Ye37", "_blank")
  }
  return (
    <>
      <div className="main-contact-bg">
        <AnimatedContactHero />
        <div className="contact-container">
          {/* First iframe */}
          <iframe
            title="Speedup Infotech HQ"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.132387105852!2d73.84381310842575!3d18.52291868249838!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf1643f8c351%3A0x40ac02878f9919e5!2sSpeedUp%20Infotech!5e0!3m2!1sen!2sin!4v1704799541221!5m2!1sen!2sin"
            width="100%"
            height="400"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>


        </div>

        <div
          className="container contact-box-container"
          style={{ marginTop: "-100px" }}
        >
          <div className="contact-box" onClick={() => viewMap1()} >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              className="bi bi-geo-alt-fill"
              viewBox="0 0 16 16"
            >
              <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
            </svg>
            <h4>Speedup - Head Office</h4>
            <p>
              3rd Floor, Avenir Building, JM Road Shivaji Nagar, Pune -
              Maharashtra-411004
            </p>
            <br />

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              className="bi bi-telephone-fill"
              viewBox="0 0 16 16"
            >
              <path d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
            </svg>
            <h4>Phone</h4>
            <p>+91 7219777599 / +91 9975127827</p>

            <br />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              className="bi bi-envelope-fill"
              viewBox="0 0 16 16"
            >
              <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
            </svg>
            <h4>Email</h4>
            <p>
              support@speedupinfotech.com
            </p>
            <br />

            <button style={{ backgroundColor: 'white', color: '#d41b12', fontSize: '15px', padding: '10px 20px', fontWeight: 'bold' }}>Visit Map</button>

          </div>

        <div className="contact-box contact-box-2" onClick={() => viewMap2()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="currentColor"
            className="bi bi-geo-alt-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
          </svg>
          <h4>Speedup - Kothrud Branch</h4>
          <p>
            Speedup Infotech, S No 19/1B, Bunglow No 3, 2nd Floor, Nirzer
            Society, Gulwani Maharaj Path, Opposite Gandhi Lawns, Kothrud,
            Pune 411038
          </p>


          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            className="bi bi-telephone-fill"
            viewBox="0 0 16 16"
          >
            <path d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
          </svg>
          <h4>Phone</h4>
          <p>+91 8329784612</p>

          <br />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="currentColor"
            className="bi bi-envelope-fill"
            viewBox="0 0 16 16"
          >
            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
          </svg>
          <h4>Email</h4>
          <p>
            suraj@speedupinfotech.com
          </p>

          {/* button */}

          <br />

          <button style={{ backgroundColor: 'white', color: '#d41b12', fontSize: '15px', padding: '10px 20px', fontWeight: 'bold' }}>Visit Map</button>

        </div>

      </div>
    </div >

      {/* Form */ }
      < h1
  style = {{
    textAlign: "center",
      margin: "50px auto",
        color: "black",
          borderBottom: "3px solid #ed2d16",
            width: "300px",
        }
}
      >
  Get in Touch
      </h1 >
  <div className="contact-form-container">
    <form onSubmit={handleOnSubmit} className="contact-form">
      <div className="form-group">
        <input
          type="text"
          className="contact-form-input"
          placeholder="Full Name"
          required
        />
      </div>
      <div className="form-group">
        <input
          type="email"
          className="contact-form-input"
          placeholder="Enter Your Mail"
          required
        />
      </div>
      <div className="form-group">
        <input
          type="tel"
          className="contact-form-input"
          placeholder="Mobile Number"
          required
        />
      </div>
      <div className="form-group">
        <textarea
          className="contact-form-input"
          rows="5"
          placeholder="Message..."
          required
        ></textarea>
      </div>
      <button type="submit" className="contact-form-btn">
        Submit Message
      </button>
    </form>
  </div>
{/* End of Form */ }
    </>
  );
};

export default Contact;
