import React, { useEffect, useState, useRef } from "react";
import "../Css/Hero.css";
import Form from "./UI/Form";
import EnquiryForm from "./UI/EnquiryForm"; // Import the EnquiryForm component

export const Hero = () => {
  const [count, setCount] = useState(0);
  const dynamicContentRef = useRef(null);
  const [underlineWidth, setUnderlineWidth] = useState(0);
  const [isEnquiryFormVisible, setEnquiryFormVisible] = useState(false); // State to manage EnquiryForm visibility

  const dynamicContent = [
    "SpeedUp Infotech",
    "Trainers",
    "Coding",
    "Mock Interviews",
  ];

  useEffect(() => {
    if (count > dynamicContent.length - 1) {
      setCount(0);
    }
    const interval = setInterval(() => {
      setCount((prev) => prev + 1);
    }, 1500);

    return () => {
      clearInterval(interval);
    };
  }, [count]);

  useEffect(() => {
    if (dynamicContentRef.current) {
      setUnderlineWidth(dynamicContentRef.current.offsetWidth);
    }
  }, [count]);

  // Pdf file
  const handleDownload = () => {
    const pdfPath = "MERN_Corporate_Training_Syllabus.pdf";
    const link = document.createElement("a");
    link.href = pdfPath;
    link.download = pdfPath.substr(pdfPath.lastIndexOf("/") + 1);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Show the EnquiryForm modal
    setEnquiryFormVisible(true);
  };

  return (
    <div className="hero-container">
      <div className="hero-left">
        <div className="hero-head">
          <h1>Unlock Your Potential with</h1>
          <p ref={dynamicContentRef} className="hero-dyn">
            {dynamicContent[count]}
          </p>
          <div
            className="hero-underline"
            style={{ width: `${underlineWidth}px` }}
          ></div>
          <div className="hero-disc">
            <p>
              Kickstart your journey with us! Expert training in the latest
              technologies like <span style={{color:'#ed2b16'}}>MERN Stack, Python Full Stack, Data Science, and
                Data Analyst Training. </span> Let's build your future together! Unlock
              earning potential up to 10 LPA.
            </p>
            <button
              className="hero-btn"
              text="Explore Courses"
              onClick={handleDownload}
            >
              Explore Courses
            </button>
          </div>
        </div>
      </div>
      <div className="hero-right">
        <Form />
      </div>

      {/* Enquiry Form Modal */}
      {isEnquiryFormVisible && (
        <EnquiryForm
          isVisible={isEnquiryFormVisible}
          onClose={() => setEnquiryFormVisible(false)} // Hide the form on close
        />
      )}
    </div>
  );
};

export default Hero;
