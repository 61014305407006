import React from "react";
import employee1 from "../almabetter resoures/employee1.png";
import employee2 from "../almabetter resoures/employee2.png";
import employee3 from "../almabetter resoures/employee3.png";
import employee4 from "../almabetter resoures/employee4.png";
import graphics from "../almabetter resoures/graphics.png";
import about1 from "../almabetter resoures/aboutimg1.png";
import about2 from "../almabetter resoures/aboutimg2.png";
import studentImg from "../almabetter resoures/student.png";
import trainer1 from "../almabetter resoures/trainer-pr.png";
import "../Css/About.css";

const About = () => {
  return (
    <>
      <div className="container">
        <h1 className="title">
          <span className="highlight" style={{ background: 'none' }}>Your Journey </span>Starts Here
        </h1>


        {/* *************************************************************************** */}
        {/* <div className="button-row">
          <button className="journey-button">Demo Session</button>
          <button className="journey-button">Enroll</button>
          <button className="journey-button">Study Material & Syllabus</button>
          <button className="journey-button">Online / Offline Training</button>
        </div>

        <div className="button-row">
          <button className="journey-button">Course Certificate</button>
          <button className="journey-button">Soft Skill Session</button>
          <button className="journey-button">Mock Interview</button>
          <button className="journey-button">Live Project Work</button>
        </div>

        <div className="button-row">
          <button className="journey-button">Assured Placement</button> */}
        {/*        </div> */}

        {/* *************************************************************************** */}


        <img src="aboutimg3.png" alt="" className="rocketImg" />


      </div>


      {/* ----------------------Second Section start---------------------- */}

      <div className="about-container1">
        <div className="about-text1">
          <h1 className="about-title">Speedup Infotech</h1>
          <p className="about-description">
            The Speedup Infotech is the perfect choice for
            aspirers who are looking for comprehensive and
            engaging <b>MERN Stack</b> /
            <b> Python Full Stack</b> /
            <b> Data Science</b> /
            <b> Data Analyst</b> Training  in <b>Pune.</b>
            Our experienced trainers will teach you the basics
            of Each Technology so
            that you can build powerful web applications with
            ease to create robust web applications from
            scratch or improve your current skillset using
            industry-standard technologies.
          </p>
        </div>
        <div className="about-image1">
          <img src='aboutimg4.png' alt="impact-graph" style={{ marginLeft: '80px' }} />
        </div>
      </div>
      {/* ----------------------Second Section end---------------------- */}

      {/* section third start */}
      <div className="about-container1">
        <div className="about-image1">
          <img src={about2} alt="impact-graph" />
        </div>
        <div className="about-text1">
          <h1 className="" style={{ color: 'black' }}>Why You Choose <br /><span className="about-title">Speedup Infotech</span> </h1>
          <p className="about-description">
            Speedup Infotech offers comprehensive and engaging training programs for aspirants looking to enhance their skills in <b>MERN Stack</b>, <b>Python Full Stack</b>, and <b>Data Science & Data Analytics</b>. Our expert trainers will guide you through the core concepts, from building powerful web applications using <b>MERN Stack</b> with <b>MongoDB</b>, <b>Express.js</b>, <b>React.js</b>, and <b>Node.js</b>, to mastering Python with frameworks like <b>Django</b> and <b>Flask</b> for full-stack development. Additionally, you'll learn essential data analysis techniques, working with tools like <b>Python</b>, <b>pandas</b>, and <b>NumPy</b>, and apply machine learning algorithms with <b>scikit-learn</b> to gain valuable insights from data. Our training is designed to help you build real-world applications and gain job-ready skills to excel in the tech industry.
          </p>

        </div>
      </div>

      {/* section third end  */}



      {/* ***************************************************************************} */}

      {/* secton fourth start */}
      <div className="journey-section">
        <h1 className="journey-title">
          <span className="highlight" style={{ background: 'none' }}>All You Have To Do This </span>To Start
          Journey With
          <br /> Speedup Infotech
        </h1>


        <img src="aboutimg5.png" alt="" className="journeyimg" />


      </div>

      {/* ***************************************************************************} */}

      {/* secton fourth end */}

      {/* --------------fifth section starts here ------------------------------- */}
      {/* <div className="impactful-section"> */}
      <div className="section-header">
        <h1 className="section-title">
          Driving impactful
          <span className="highlight-text" style={{ color: 'white' }}> Career Results</span>
        </h1>
      </div>
      <div className="about-container1">
        <div className="about-image1">
          <img className="impact-image" src={studentImg} alt="impact-graph" />
        </div>
        <div className="about-text1">
          <h1 className="about-title">Speedup Infotech <br /> <span style={{ color: 'black', fontSize: '23px' }}>Super Toppers</span></h1>
          <p className="about-description">
            Speed Up Infotech has been <b> transformative</b>
            <b>experience </b> for me. As one of their <b> student</b>, I can
            confidently say that their training programs are
            <b> top Notch</b>.The hands-on approach and real-world
            projects have not only honed my <b>technical skills</b> but
            also boosted my confidence. The <b>support</b> and
            <b> guidance</b> from the trainers and staff have been
            invaluable. <b>Thanks to Speed Up Infotech,</b>  I feel
            well-prepared and excited to embark on my career
            in the <b>IT industry </b>. I highly recommend Speed Up
            Infotech to anyone looking to kickstart their
            career in tech.
          </p>
        </div>

      </div>
      {/* </div> */}

      {/* --------------fifth section starts here ------------------------------- */}

      {/* --------------------Sixth section starts here ---------------------------- */}
      {/* <div style={{ display: "flex", marginTop: "30px", marginBottom: "30px" }}>
        <div
          style={{
            backgroundColor: "#ed2d16",
            width: "700px",
            height: "500px",
          }}
        >
          <div style={{ padding: "30px", paddingLeft: "60px" }}>
            <h1 style={{ color: "#fff" }}>
              Carrier<span style={{ color: "black" }}> Blogs</span>
              <br />
              Pratik Sable, Our <br />
              <span style={{ color: "black" }}>Corporate Trainer Talks</span>
            </h1>
            <p style={{ color: "#fff" }}>
              It's a long established fact that a reader will
              <br />
              be distracted by the readable content of a page
              <br />
              when looking at its layout. The point of using
              <br />
              Lorem Ipsum is that it has a more-or-less normal
              <br />
              distribution of letters, as opposed to using
              <br />
              'Content here'
              <br />
            </p>
            <h2 style={{ color: "#fff" }}>Pratik Sable</h2>
            <p style={{ color: "#fff" }}>Corporate Trainer</p>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#ed2d16",
            width: "500px",
            height: "500px",
            borderTopRightRadius: "50%",
            borderBottomRightRadius: "50%",
          }}
        >
          <img src={trainer1} alt="impact-graph" />
        </div>
      </div> */}

      <div className="about-container">
        <div className="about-left">
          <div className="about-content p-4">
            <h1>
              Carrier<span> Blogs</span>
              <br />Pratik Sable, Our <br />
              <span>Corporate Trainer Talks</span>
            </h1>
            <p style={{ textAlign: 'left' }}>
              At Speedup Infotech, we offer insightful and dynamic sessions through our
              <span>Corporate Project Based Training </span>. In my journey as a corporate trainer, 
              I've had the privilege of mentoring aspiring professionals and helping them carve out 
              successful careers. Through my <span>this journey </span>, I aim to provide real-world insights into the skills and knowledge required to excel in today’s fast-paced, ever-evolving tech landscape. Whether it's sharing strategies for mastering new technologies, preparing for interviews, or discussing the latest trends in the tech industry, my goal is to empower individuals to take their careers to the next level. I believe that continuous learning, mentorship, and a passion for growth are the keys to unlocking your potential.

              <br />
            </p>
            <h2>Pratik Sable</h2>
            <p>Corporate Trainer</p>
          </div>
        </div>
        <div className="about-right">
          <img src={trainer1} alt="impact-graph" />
        </div>
      </div>

      {/* --------------------Sixth section starts here ---------------------------- */}

      {/* --------------------Seventh section starts here ---------------- */}



      {/* Second Testimonial card start */}
      <div className="about-container1">
        <div className="about-text1">
          <h1 className="about-title">Employee</h1>
          <h3>Testimonial</h3>
          <p style={{ fontWeight: 'bold' }}>
            SpeedUp Infotech is a hub of innovation and
            growth, offering a platform where ideas flourish
            and relationships thrive. As a <b>Business
              Development Associate, </b> I've experienced
            firthand the company's commitment to
            excellence and its unwavering support for its
            employees. It's truly inspiring to be part of a team
            that values creativity, collaboration, and continuous
            imporvement.
          </p>
          <h2>Pooja Lende</h2>
          <p>Business Developer</p>
        </div>
        <div className="about-image1">
          <img src={employee1} alt="impact-graph" style={{ marginLeft: '80px' }} />
        </div>
      </div>
     
      

      {/* Fifth Testimonial card start */}
      <div className="about-container1">
        <div className="about-image1">
          <img src={graphics} alt="impact-graph" />
        </div>
        <div className="about-text1">
          <h1 className="about-title">Employee</h1>
          <h3>Testimonial</h3>
          <p style={{ fontWeight: 'bold' }}>
            SpeedUp Infotech is a hub of innovation and
            growth, offering a platform where ideas flourish
            and relationships thrive. As a <b> Business
              Development Associate</b>, I've experienced
            firthand the company's commitment to
            excellence and its unwavering support for its
            employees. It's truly inspiring to be part of a team
            that values creativity, collaboration, and continuous
            imporvement.
          </p>
        </div>
      </div>
      {/* Fifth Testimonial card end */}

      {/* --------------------Seventh section starts here ---------------- */}
    </>
  );
};

export default About;
