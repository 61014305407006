import React, { useState, useEffect } from "react";
import Marquee from "react-fast-marquee";
import "../../Css/RunningBanner.css";
import EnquiryForm from "./EnquiryForm"; // Import the EnquiryForm
import ImageForm from "./ImageForm";
import newbatch from "../../almabetter resoures/Newbatch.jpg";

const RunningBanner = () => {
  const [isEnquiryFormVisible, setEnquiryFormVisible] = useState(false);
  // const [isImageFormVisible, setImageFormVisible] = useState(false);
  const [nextBatchDate, setNextBatchDate] = useState("");

  // Function to calculate the next Monday or Thursday
  const getNextBatchDate = () => {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday

    // Calculate how many days until the next Monday (1) or Thursday (4)
    let daysUntilNextMonday = (8 - dayOfWeek) % 7 || 1; // Days until next Monday
    let daysUntilNextThursday = (11 - dayOfWeek) % 7 || 1; // Days until next Thursday

    // Get the closest upcoming Monday or Thursday
    const nextMonday = new Date(today.getTime() + daysUntilNextMonday * 24 * 60 * 60 * 1000);
    const nextThursday = new Date(today.getTime() + daysUntilNextThursday * 24 * 60 * 60 * 1000);

    // Set the closest upcoming date
    if (daysUntilNextMonday <= daysUntilNextThursday) {
      return nextMonday;
    } else {
      return nextThursday;
    }
  };

  useEffect(() => {
    const nextBatch = getNextBatchDate();
    setNextBatchDate(nextBatch.toDateString());
  }, []);

  const handleCloseEnquiryForm = () => {
    setEnquiryFormVisible(false);
  };

  // const handleCloseImageForm = () => {
  //   setImageFormVisible(false);
  // };

  return (
    <>
      <Marquee
        className="main-banner"
        speed={50}
        gradient={false}
        pauseOnHover={true}
      >
        <div className="running-text">
          Demo sessions Monday to Saturday &nbsp;&nbsp;Contact:+91 7219777599
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button
            className="pulse-button"
            onClick={(e) => {
              e.stopPropagation();
              setEnquiryFormVisible(true);
            }}
          >
            Register
          </button>
          <a
            style={{ marginLeft: "20px", color: "#ed2d16", cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            Upcoming Batch: {nextBatchDate}
          </a>
        </div>
      </Marquee>

      {/* Enquiry Form Modal */}
      {isEnquiryFormVisible && (
        <EnquiryForm
          isVisible={isEnquiryFormVisible}
          onClose={handleCloseEnquiryForm}
        />
      )}

      {/* Image Form Modal */}
      {/* {isImageFormVisible && (
        <ImageForm
          isVisible={isImageFormVisible}
          onClose={handleCloseImageForm}
          imageUrl={newbatch}
        />
      )} */}
    </>
  );
};

export default RunningBanner;
